export default [
    {
        header: 'Tools',
        icon: 'Share2Icon',
        children: [
            {
                title: 'media-library',
                route: 'media-library',
                icon: 'ImageIcon',
            }
        ],
    },
]