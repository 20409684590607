export default [
    {
        header: 'Channels',
        icon: 'Share2Icon',
        children: [
            {
                title: 'Channels',
                route: 'channels',
                icon: 'Share2Icon',
            },
            {
                title: 'Posts',
                route: 'posts',
                icon: 'SendIcon',
            },
            {
                title: 'Calendar',
                route: 'calendar',
                icon: 'CalendarIcon',
            },
            {
                title: 'Automation',
                route: 'automation',
                icon: 'RepeatIcon',
                tag: 'soon',
                tagVariant: 'light-warning',
            },
            {
                title: 'Brands',
                route: 'brands',
                icon: 'AwardIcon',
            },
            {
                title: 'Members',
                route: 'members',
                icon: 'UsersIcon',
            }
        ],
    },
]